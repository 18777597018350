import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { authHeader, baseUrlConfig } from '../../helpers/http'

const baseurl = 'job/seekers'

export const jobSeekerApi = createApi({
  reducerPath: 'job/seekers',
  baseQuery: fetchBaseQuery(baseUrlConfig),
  tagTypes: ['JobSeeker'],
  endpoints: (builder) => ({
    getJobSeeker: builder.mutation({
      query: (params) => ({
        url: `${baseurl}`,
        params,
        method: 'get'
      }),
      providesTags: ['GetJobSeeker'],
      transformResponse: (response) => response.results
    }),
    getJobSeekerAdmin: builder.mutation({
      query: (params) => ({
        url: `admin/jobs/seekers`,
        params,
        headers: authHeader(),
        method: 'get'
      }),
      providesTags: ['GetJobSeeker'],
      transformResponse: (response) => response.results
    }),
    getJobSeekerEmployer: builder.mutation({
      query: (params) => ({
        url: `employer/jobs/seekers`,
        params,
        headers: authHeader(),
        method: 'get'
      }),
      providesTags: ['GetJobSeeker'],
      transformResponse: (response) => response.results
    })
  })
})

export const {
  useGetJobSeekerAdminMutation,
  useGetJobSeekerMutation,
  useGetJobSeekerEmployerMutation
} = jobSeekerApi
