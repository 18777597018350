// ** Reducers Imports
import employerRegisterReducer from "./employer/employer_register_form_slice"
import postJobFormReducer from "./employer/post_job_form_slice"
import updateJobFormReducer from "./employer/update_job_form_slice"
import jobExcellReducer from "./job/job_export"
import jobListFilterReducer from "./job/job_list_filter_slice"
import layout from "./layout"
import navbar from "./navbar"
import seekerCVReducer from "./seeker/seeker_cv_download"
import seekerProfileReducer from "./seeker/seeker_profile_form_slice"
import ChatAction from "./chat/chatActions"
import { chatHandle } from "./chat/chatHandle"
import { adminApi } from "./admin/admin.redux"
import { adminCompanyApi } from "./admin/company"
import { authApi } from "./auth/authService"
import { companyApi } from "./company/company_service"
import { educationsApi } from "./education/educations"
import { employerAuthApi } from "./employer/auth"
import { postalApi } from "./employer/postal_service"
import { employerProfileApi } from "./employer/profile"
import { jobApplyApi } from "./job/job_apply"
import { jobBookmarkApi } from "./job/job_bookmark"
import { jobCategoriesApi } from "./job/job_categories"
import { jobIndustriesApi } from "./job/job_industries"
import { jobPostApi } from "./job/job_post"
import { jobSeekerApi } from "./job/job_seeker"
import { jobTypeApi } from "./job/job_type"
import { jobApplicationApi } from "./jobApplication/jobApplicationService"
import { jobStatsApi } from "./job/job_stats"
import { adminJobPostApi } from "./jobPost/jobPostService"
import { roleApi } from "./rolepermission/roleService"
import { seekerAuthApi } from "./seeker/auth"
import { seekerProfileApi } from "./seeker/profile"
import { seekerDocumentApi } from "./seeker/seekerDocument"
import { seekerEducationsApi } from "./seeker/seeker_education"
import { seekerExperienceApi } from "./seeker/seeker_experience"
import { seekerJapanExperienceApi } from "./seeker/seeker_japan_experience"
import { seekerLanguageApi } from "./seeker/seeker_language"
import { seekerLanguagesApi } from "./seeker/seeker_languages"
import { seekerQualificationApi } from "./seeker/seeker_qualification"
import { candidatesApi } from "./employer/candidate"
import { subscriptionsApi } from "./subscriptions/subscriptions"
import { changePasswordApi } from "./admin/changePassword"

const rootReducer = {
    navbar,
    layout,
    employerRegister: employerRegisterReducer,
    seekerProfile: seekerProfileReducer,
    postJobForm: postJobFormReducer,
    updateJobForm: updateJobFormReducer,
    jobListFilter: jobListFilterReducer,
    seekerCV: seekerCVReducer,
    jobExcell: jobExcellReducer,
    chatAction: ChatAction,
    [chatHandle.reducerPath]: chatHandle.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
    [adminCompanyApi.reducerPath]: adminCompanyApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [jobCategoriesApi.reducerPath]: jobCategoriesApi.reducer,
    [jobIndustriesApi.reducerPath]: jobIndustriesApi.reducer,
    [jobTypeApi.reducerPath]: jobTypeApi.reducer,
    [educationsApi.reducerPath]: educationsApi.reducer,
    [seekerDocumentApi.reducerPath]: seekerDocumentApi.reducer,
    [jobPostApi.reducerPath]: jobPostApi.reducer,
    [jobApplyApi.reducerPath]: jobApplyApi.reducer,
    [seekerAuthApi.reducerPath]: seekerAuthApi.reducer,
    [seekerProfileApi.reducerPath]: seekerProfileApi.reducer,
    [seekerEducationsApi.reducerPath]: seekerEducationsApi.reducer,
    [seekerExperienceApi.reducerPath]: seekerExperienceApi.reducer,
    [seekerJapanExperienceApi.reducerPath]: seekerJapanExperienceApi.reducer,
    [seekerLanguagesApi.reducerPath]: seekerLanguagesApi.reducer,
    [seekerDocumentApi.reducerPath]: seekerDocumentApi.reducer,
    [seekerLanguageApi.reducerPath]: seekerLanguageApi.reducer,
    [seekerQualificationApi.reducerPath]: seekerQualificationApi.reducer,
    [employerAuthApi.reducerPath]: employerAuthApi.reducer,
    [employerProfileApi.reducerPath]: employerProfileApi.reducer,
    [postalApi.reducerPath]: postalApi.reducer,
    [companyApi.reducerPath]: companyApi.reducer,
    [roleApi.reducerPath]: roleApi.reducer,
    [adminJobPostApi.reducerPath]: adminJobPostApi.reducer,
    [jobBookmarkApi.reducerPath]: jobBookmarkApi.reducer,
    [jobSeekerApi.reducerPath]: jobSeekerApi.reducer,
    [jobApplicationApi.reducerPath]: jobApplicationApi.reducer,
    [jobPostApi.reducerPath]: jobPostApi.reducer,
    [jobStatsApi.reducerPath]: jobStatsApi.reducer,
    [candidatesApi.reducerPath]: candidatesApi.reducer,
    [subscriptionsApi.reducerPath]: subscriptionsApi.reducer,
    [changePasswordApi.reducerPath] : changePasswordApi.reducer
}

export default rootReducer
