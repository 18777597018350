import "@src/assets/css/chat.css"

export const HeaderPeople = ({ onChangeText, placeholder = 'Cari Nama' }) => {
    const _onChange = (e) => {
        onChangeText && onChangeText(e.target.value)
    }

    return (
        <div className=" pb-1 px-1 header-chat-contact">
            <h4 className="py-1 mb-0">Chat</h4>
            <div className="input-group input-group-sm">
                <input
                    type="text"
                    className="form-control search-chat"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder={placeholder}
                    onChange={_onChange}
                />
            </div>
        </div>
    )
}