// import { baseUrlConfig } from "@src/helpers/http";
import { io } from "socket.io-client"

export const initSocket = io(`${process.env.REACT_APP_SOCKET}`, {
    path: '/socket.io',
    port: 80,
    timestampRequests: true,
    reconnectionAttempts: 10,
    timeout: 36000,
    forceNew: false,
    reconnection: true,
    autoConnect: false,
    auth: (cb) => {
        cb({ token: localStorage.getItem('token') })
    },
    transports: ['websocket', 'polling']
})