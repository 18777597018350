import "@src/assets/css/chat.css"
import { Minimize2 } from "react-feather"
import { Button } from "reactstrap"
import Avatar from '@src/assets/images/chat/avatar_chat.jpg'

export const HeaderBody = ({ onClick, name, status = false, imgUrl }) => {

    return (
        <header className="header-chat-contact d-flex justify-content-between align-items-center py-1 z-3 px-1">
            <div className="d-flex align-items-center">
                <img
                    src={imgUrl || Avatar}
                    className="rounded-circle profile-chat-pv"
                    alt="profil"
                />
                <div>
                    <h5 className="mb-0">{name}</h5>
                    <p className="indicator-chat mb-0" style={{
                    }}>{status ? 'Online' : 'Offline'}</p>
                </div>
            </div>

            <div>
                <Button color="danger" size="sm" onClick={onClick}>
                    <Minimize2 size={16} />
                </Button>
            </div>
        </header>
    )
}