import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { Button, Col, Container, Input, InputGroup, Row, Spinner } from "reactstrap"
import { MessageSquare, Minimize2, Send, Mail } from "react-feather"
import { useLocation } from 'react-router-dom';
import { FooterBody, HeaderBody, HeaderPeople, ListPeople } from "@src/components"
import Message from "@src/components/chat/Message"
import { useSelector, useDispatch } from "react-redux"
import { hideChat, showChat, onActiveChat } from "@store/chat/chatActions"
import { useGetContactChatMutation, useGetMessageMutation } from "@src/redux/chat/chatHandle"
import { loggedInfo } from "@src/helpers/checkAuth"
import moment from "moment"
import { useMediaQuery } from 'usehooks-ts'
import { SocketContext } from "@src/utility/context/Socket";
import toast from 'react-hot-toast';
import "@src/assets/css/chat.css"

import EmptyMessage from "@src/assets/images/chat/empty_message.jpg";
import NotificationSound from "@src/assets/sounds/notification-sound.aac";


export const ChatProvider = () => {
    const audioPlayer = useRef(null);
    const socket = useContext(SocketContext);
    const matches = useMediaQuery('(min-width: 800px)')
    const dispatch = useDispatch()
    const location = useLocation()
    const { token } = loggedInfo()
    const { isOpen, activeChat } = useSelector(state => state.chatAction.value)
    const [GetContact, dataContact] = useGetContactChatMutation();
    const [GetMessage, dataHistory] = useGetMessageMutation();

    const [searchName, setSearchName] = useState('');
    const [dataMessage, setDataMessage] = useState([]);
    const [dataAllPeople, setDataAllPeople] = useState(dataContact.data || []);

    const { email } = loggedInfo();
    const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
    const playAudio = useCallback(() => {
        try {
            if (!isSafari) {
                audioPlayer.current && audioPlayer.current.play();
                audioElement.play();
            }
            // audioPlayer.current && audioPlayer.current.play();
            // const audioElement = new Audio(NotificationSound);
        } catch (error) {
            console.log('play sound err: ', error);
        }
    }, [audioPlayer])

    const addMessage = useCallback((message) => {
        if (!isOpen) {
            toast(message?.message || '', {
                position: 'top-center',
                icon: <Mail />
            });
        }
        if (message?.chatId === activeChat) {
            setDataMessage(prev => [...prev, message]);
            playAudio()
        }
    }, [activeChat, isOpen]);

    useEffect(() => {
        socket.on("getMessage", addMessage);
        return () => socket.off('getMessage', addMessage);
    }, [addMessage])

    useEffect(() => {

        socket.on("connect", () => {
            if (socket.connected) {
                console.log(socket.connected ? 'connected' : 'disconnect');
                GetContact();
            }
        });

        socket.on("listChats", (data) => {
            if (data) {
                setDataAllPeople(data)
            }
        });

        // handling error
        socket.on("disconnect", (reason) => {
            console.log('disconnect');
            if (reason === "io server disconnect") {
                socket.connect();
            }
        });

        socket.on("connect_error", () => {
            console.log('connect_error...');
        });

        socket.io.on("error", () => {
            console.log('error: ');
        });


        return () => {
            socket.off("connect");
            socket.off("listChats");
            socket.off("disconnect");
            socket.off("connect_error");
        }
    }, []);

    useEffect(() => {
        if (dataHistory.data && dataHistory.data.length > 0) {
            setDataMessage(dataHistory.data)
        } else {
            setDataMessage([])
        }
    }, [dataHistory, activeChat]);

    useEffect(() => {
        if (dataContact.data) {
            setDataAllPeople(dataContact.data)
        } else {
            setDataAllPeople([])
        }
    }, [dataContact])

    useEffect(() => {
        if (dataContact.data) {
            if (dataContact.data.length > 0 && !activeChat) {
                const roomId = dataContact.data[0].id;
                dispatch(onActiveChat(roomId));
            }
        }

        // selalu get data ketika ada perubahan active contact
        if (activeChat) {
            GetMessage(activeChat)
        }

    }, [dataContact, activeChat])

    useEffect(() => {
        // berfungsi sbg perubahan pada component
        if (!token) {
            dispatch(hideChat());
            dispatch(onActiveChat(null));
            socket.disconnect()
        }

        // reconnect socket on reload
        if (token && socket.disconnected) {
            socket.connect()
        }

        return () => {
            socket.close()
        }

    }, [location, token, socket])

    useEffect(() => {
        GetContact()
    }, [isOpen])

    const onOpenChat = useCallback(() => {
        dispatch(showChat(activeChat))
    }, [activeChat])

    const onCloseChat = () => {
        dispatch(hideChat())
    }

    const onClickContact = useCallback((e) => {
        const roomId = e.id;
        if (activeChat !== roomId) {
            dispatch(onActiveChat(roomId))
            GetMessage(roomId)
        }
    }, [activeChat]);

    const _onMessage = useCallback((newMsg) => {
        const newArr = [...dataMessage];
        if (dataMessage.length > 0) {
            newArr.push(newMsg)
            setDataMessage(newArr);
        } else {
            setDataMessage([newMsg])
        }
    }, [dataMessage]);

    const onSearchPeople = (name) => {
        setSearchName(name)
    }

    const ListContact = useMemo(() => {
        const resultData = dataAllPeople || [];
        const regex = new RegExp(searchName, 'i');
        return resultData.filter((o) => regex.test(o.members[0]?.company?.name || o?.members[0]?.firstName || ''));
    }, [dataAllPeople, searchName]);

    const detailActive = useMemo(() => {
        let data = activeChat ? dataAllPeople?.filter(i => i.id === activeChat) : dataAllPeople.length > 0 ? dataAllPeople[0] : [];
        data = data.length > 0 ? data[0] : {};

        return {
            firstName: data?.members?.length > 0 ? data?.members[0]?.company?.name || data?.members[0]?.firstName : '-',
            id: data?.members?.length > 0 ? data?.members[0]?.id : '-',
            photo: data?.members?.length > 0 ? data?.members[0]?.photo : null
        }
    }, [dataAllPeople, activeChat]);

    const messages = useMemo(() => {
        // eslint-disable-next-line prefer-const
        let dataRev = [];
        dataRev = dataRev.concat(dataMessage !== undefined ? dataMessage : []);
        return dataRev.sort((a, b) => moment(a.createdAt).format('x') - moment(b.createdAt).format('x')) || [];
    }, [dataMessage]);

    const receiverId = useMemo(() => {
        const dataReceiver = dataContact.data || [];
        const item = dataReceiver.filter((o) => o.id === activeChat).map((itm) => {
            return itm?.members[0]?.id || null
        })
        return item.length > 0 ? item[0] : null;
    }, [dataContact.data, activeChat]);

    const haveNewMessage = useMemo(() => {
        const newMsg = dataAllPeople.length > 0 ? dataAllPeople.filter((o) => o.isHasNewMessage === true) : []
        return newMsg.length || 0
    }, [dataAllPeople])

    return (
        <div className="clearfix">
            <audio ref={audioPlayer} src={NotificationSound} />
            <Container
                className="body-chat
                shadow-lg
                rounded-3 border
                position-fixed 
                bottom-0 
                end-0
                mb-2
                mx-2
                bg-white
                "
                style={{ display: matches ? isOpen ? "block" : "none" : "none", transform: `scale(${isOpen ? 1 : 0})` }}
            >
                <Row md="2" className="h-100">
                    <Col md={4} className="d-flex flex-column  border-end px-0">
                        <HeaderPeople onChangeText={onSearchPeople} />
                        <ListPeople
                            data={ListContact}
                            idActive={activeChat}
                            loading={dataContact?.isLoading}
                            onClick={onClickContact} />
                    </Col>

                    {/* TODO: Room Chat */}
                    <Col md={8} className="border-start px-0" >
                        <HeaderBody
                            onClick={onCloseChat}
                            name={detailActive?.firstName}
                            imgUrl={detailActive?.photo}
                            status={true}
                        />
                        {/* Message Room */}
                        <div className="z-1" id="messageFeed"
                            style={{
                                display: 'flex',
                                position: 'relative',
                                overflowY: 'auto',
                                flex: 'none',
                                order: 2,
                                flexDirection: 'column-reverse',
                                flexShrink: 1,
                                flexFlow: 1,
                                height: '22em',
                                scrollbarColor: 'rgba(255,255,255, .16)',
                                scrollbarWidth: 'thin',
                                verticalAlign: 'baseline',
                                scrollBehavior: 'smooth',

                            }}>
                            {dataHistory?.isLoading && <div key={'asd'} className="d-flex justify-content-center align-items-center my-3">
                                <Spinner color="primary" />
                            </div>}


                            <ol className="py-1 ul-message z-1" >
                                {(!dataHistory?.isLoading && messages.length > 0) ? messages.map((item) => (
                                    <Message
                                        key={item?.id}
                                        message={item}
                                        self={email} />
                                )).reduce((prev, next, currentIndex) => [
                                    prev,
                                    <div key={`cb-${currentIndex}`} className="mb-1" />,
                                    next
                                ]) : !dataHistory?.isLoading && (
                                    <div key={'empty'} className="my-2 w-100 text-center">
                                        <img src={EmptyMessage} width={152} />
                                        <p className="text-secondary mt-1">Belum ada pesan.</p>
                                    </div>
                                )
                                }
                            </ol>


                        </div>

                        {/* Footer Room */}
                        <FooterBody
                            roomId={activeChat}
                            receiverId={receiverId}
                            placeholder="Tulis pesan..."
                            onMessage={_onMessage}
                        />
                    </Col>
                </Row>
            </Container>

            {/* Button Floating */}
            {
                matches && token && (
                    <Button
                        onClick={onOpenChat}
                        color="primary"
                        className="
                        btn-chat-float
                            shadow-lg 
                            round-5 
                            rounded-pill 
                            position-fixed 
                            bottom-0 
                            end-0
                            mb-4
                            mx-3
                            bg-white
                            "
                        style={{ zIndex: 99 }}
                    >
                        <MessageSquare />
                        &nbsp;&nbsp; Chat
                        {
                            haveNewMessage > 0 && (
                                <div className="dot-new-msg">
                                    <span>{haveNewMessage}</span>
                                </div>
                            )
                        }
                    </Button>
                )
            }
        </div>
    )
}
