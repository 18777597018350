import { useCallback, useContext, useEffect, useState } from "react"
import { Send } from "react-feather"
import { Button, Spinner } from "reactstrap"
import { useSendMessageMutation } from "@src/redux/chat/chatHandle"

import "@src/assets/css/chat.css"
import { SocketContext } from "@src/utility/context/Socket"

export const FooterBody = ({ placeholder, roomId, senderId = '', receiverId, onMessage }) => {
    const socket = useContext(SocketContext);
    const [SendMessage, { data, isLoading, reset }] = useSendMessageMutation();

    const [msg, setMsg] = useState('');

    useEffect(() => {
        if (data) {
            onMessage && onMessage(data)
        }
    }, [data, onMessage])

    useEffect(() => {
        if (!isLoading && data) {
            const payload = {
                chatId: roomId,
                senderId,
                receiverId,
                message: JSON.stringify(msg)
            }
            socket.emit("sendMessage", payload)
            setMsg('');
            document.getElementById("textInput").value = "";
            reset()
        }
    }, [msg, receiverId, roomId, senderId, data, isLoading])

    const _onClick = useCallback(() => {
        const payload = {
            chatId: roomId,
            senderId,
            receiverId,
            message: msg
        }

        SendMessage(payload);

    }, [msg, receiverId, roomId, senderId]);

    const onChange = (e) => {
        setMsg(e.target.value);
        reset()
    }

    return (
        <footer className="p-1 position-relative d-block footer-chat" style={{
            flexBasis: 'auto',
            order: 3,

        }}>
            <textarea id="textInput" className="form-control"
                style={{
                    borderRadius: 10,
                    borderWidth: 2,
                    paddingRight: 52
                }}
                placeholder={placeholder}
                rows="1"
                disabled={isLoading}
                value={msg}
                onChange={onChange}
            ></textarea>
            <div className="position-absolute top-50 start-100 translate-middle"
                style={{
                    marginLeft: -42
                }}>
                <Button disabled={isLoading} onClick={_onClick} color="link" >
                    {
                        isLoading ? <Spinner size={'sm'} /> : <Send />
                    }
                </Button>
            </div>

        </footer>
    )
}