/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react"
import { Navigate, redirect, useNavigate } from "react-router-dom"

export function loggedInfo() {
  return {
    token: localStorage.getItem('token'),
    role: localStorage.getItem('role'),
    email: localStorage.getItem('email')
  }
}


export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  const checkTokenExpiration = (token) => {
    try {
      const { exp } = JSON.parse(Buffer.from(token.split('.')[1], 'base64'))
      const currentTime = new Date().getTime() / 1000
      if (currentTime > exp) return true
    } catch (e) {
      console.log(e)
      return true
    }
    return false
  }

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (!token) setIsAuthenticated(false)

    const isExpired = checkTokenExpiration(token)
    if (isExpired) setIsAuthenticated(false)

    setIsAuthenticated(true)
  }, [])

  return { isAuthenticated }
}

export const getRedirectAuth = (rolesCode) => {
  if (rolesCode === 'seeker') return '/seeker/dashboard/profile'
  if (rolesCode === 'employer') return '/employer/dashboard/profile'
  if (rolesCode === 'superadmin') return '/dashboard'
  return '/'
}

export const withAuth = WrappedComponent => props => {
  const [role, setRole] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    const role = localStorage.getItem('role')
    setRole(role)

    // if (role !== props.role) {
    //   navigate(-1)
    // }

    if (role === null || role === undefined) {
      navigate('/login')
      window.location.reload()
      return <></>
    }

    if (role !== props.role && props.role !== 'all') {
      return <></>
    }

  }, [])


  return <WrappedComponent {...props} />
}


export const protectPage = ({ currentPath = null }) => {
  const { role, token } = loggedInfo()

  if (token !== null && (currentPath === role)) {
    return true
  } else {
    return false
  }
}