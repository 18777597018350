import { useMemo } from "react"
import "@src/assets/css/chat.css"
import { Spinner } from "reactstrap"
// import { format } from "timeago.js";

import Avatar from '@src/assets/images/chat/avatar_chat.jpg'
import moment from "moment";

export const ListPeople = ({ data = [], isSearch = false, loading = false, idActive = '', onClick }) => {

    const currentData = useMemo(() => {
        try {
            if (data.length > 0) {
                return data
            }
            return []
        } catch (err) {
            return []
        }
    }, [data])
    return (
        <div className="flex-fill list-container">

            <div className="list-item" style={{
                scrollbarColor: 'rgba(255,255,255, .16)',
                scrollbarWidth: 'thin',
            }}>
                <ul>
                    {
                        loading && (
                            <div key={'lp0'} className="text-center">
                                <Spinner color="primary" size={'sm'}>
                                    loading...
                                </Spinner>
                            </div>
                        )
                    }
                    {
                        currentData.length === 0 && (
                            <li key={'lp1'}>
                                <p className="text-center text-secondary">{isSearch ? 'Tidak ditemukan!' : 'Belum ada percakapan!'}</p>
                            </li>
                        )
                    }
                    {currentData.length > 0 && currentData.map((item, index) => (
                        <li key={index + item} onClick={() => onClick && onClick(item)}>
                            <div className="list-contact item d-flex align-items-start mx-1 pointer rounded-3 p-1"
                                style={{
                                    backgroundColor: idActive === item?.id ? 'rgba(40, 102, 235,0.1)' : 'rgb(246, 246, 246)'
                                }}
                            >
                                <img
                                    src={item?.members.length > 0 ? item?.members[0].photo || Avatar : Avatar}
                                    className="rounded-3 profile-chat-list"
                                    alt="profil"
                                />
                                <div className="flex-fill">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="flex-fill">
                                            <p className=" mb-0 fw-bold ellipsis ">
                                                {item?.members.length > 0 ? item?.members[0]?.company?.name || item?.members[0]?.firstName : '-'}
                                            </p>
                                        </div>
                                        <p className="mb-0 text-body-tertiary time-chat">
                                            { item?.lastMessageData.length > 0 && moment(item?.lastMessageData[0]?.createdAt || '').format('HH:MM A')}
                                        </p>
                                    </div>
                                    <p className="mb-0 multiline-ellipsis msg-chat-list">
                                        {item?.lastMessageData.length > 0 ? item?.lastMessageData[0]?.message || '' : ''}
                                    </p>
                                </div>
                                {
                                    item?.isHasNewMessage && (
                                        <div className="dot-new-msg" />
                                    )
                                }

                            </div>
                        </li>
                    ))
                        .reduce((prev, next, currentIndex) => [
                            prev,
                            <div key={`lp-${currentIndex}`} style={{ marginBlock: 10 }} />,
                            next,
                        ])}

                </ul>
            </div>
        </div>
    )
}