import { createSlice } from "@reduxjs/toolkit"

const toggleArray = (data, value) => {
  const arr = data.split(',').filter(item => item !== "")
  const newarr = arr.includes(value) ? arr.filter(item => item !== value) : [...arr, value]
  return Array.from(newarr).join(',')
}

const appendArray = (data, value) => {
  const arr = data.split(',').filter(item => item !== "")
  const newarr = !arr.includes(value) && [...arr, value]
  return Array.from(newarr).join(',')
}

const initialState = {
  value: {
    limit: 5,
    isFindJob: false,
    jobCategoryId: '',
    jobIndustries: '',
    jobTypeId: '',
    keyword: '',
    location: '',
    date: '',
    popular: false
  }
}

export const jobListFilterSlice = createSlice({
  name: 'joblistfilter',
  initialState,
  reducers: {
    changeJobListFilter: (state, action) => {
      state.value = {
        ...state.value,
        ...action.payload
      }
    },
    toggleJobIndustriesFilter: (state, action) => {
      console.log("toggleJobIndustriesFilter")
      state.value.jobIndustries = toggleArray(state.value.jobIndustries, action.payload)
    },
    addJobIndustriesFilter: (state, action) => {
      console.log("addJobIndustriesFilter")
      state.value.jobIndustries = appendArray(state.value.jobIndustries, action.payload)
    },
    removeJobIndustriesFilter: (state, action) => {
      console.log("removeJobIndustriesFilter")
      state.value.jobIndustries = toggleArray(state.value.jobIndustries, action.payload)
    },
    toggleJobTypeFilter: (state, action) => {
      state.value.jobTypeId = toggleArray(state.value.jobTypeId, action.payload)
    },
    clearJobListFilter: (state) => {
      state.value = initialState.value
    }
  }
})

export const {
  changeJobListFilter,
  toggleJobIndustriesFilter,
  addJobIndustriesFilter,
  removeJobIndustriesFilter,
  toggleJobTypeFilter,
  clearJobListFilter
} = jobListFilterSlice.actions

export default jobListFilterSlice.reducer
