import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { baseUrlConfig } from "../../helpers/http"

const baseUrl = 'employer/candidates'

export const candidatesApi = createApi({
    reducerPath: "employer/candidates",
    baseQuery: fetchBaseQuery(baseUrlConfig),
    tagTypes: ['BestCandidates'],
    endpoints: (builder) => ({
        getBestCandidates: builder.query({
            query: () => ({
                url: `${baseUrl}`,
                method: 'GET'
            }),
            providesTags: ['BestCandidates'],
            transformResponse: (response) => response.results
        })
    })
})

export const {
    useGetBestCandidatesQuery
} = candidatesApi
