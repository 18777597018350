import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseUrlConfig, authHeader } from '../../helpers/http'

const baseurl = 'seeker/document'

export const seekerDocumentApi = createApi({
  reducerPath: 'seeker/documents',
  baseQuery: fetchBaseQuery(baseUrlConfig),
  tagTypes: ['SeekerDocument'],
  endpoints: (builder) => ({
    getDocuments: builder.mutation({
      query: () => ({
        url: `${baseurl}`,
        method: 'GET',
        headers: authHeader()
      }),
      providesTags: ['GetAllDocuments'],
      transformResponse: (response) => response.results
    }),
    getDocumentsById: builder.mutation({
      query: (id) => ({
        url: `${baseurl}/${id}`,
        method: 'GET',
        headers: authHeader()
      }),
      providesTags: ['GetSingleDocuments'],
      transformResponse: (response) => response.results
    }),
    postDocuments: builder.mutation({
      query: (body) => ({
        url: `${baseurl}`,
        method: 'POST',
        headers: authHeader(),
        body
      }),
      providesTags: ['PostSingleDocuments'],
      transformResponse: (response) => response.results
    }),
    updateDocuments: builder.mutation({
      query: (data) => ({
        url: `${baseurl}/${data.id}`,
        method: 'PUT',
        headers: {
          ...authHeader(),
          contentType: 'multipart/form-data'
        },
        body: data.body
      }),
      providesTags: ['UpdateSingleDocuments'],
      transformResponse: (response) => response.results
    }),
    deleteDocuments: builder.mutation({
      query: (id) => ({
        url: `${baseurl}/${id}`,
        method: 'DELETE',
        headers: authHeader()
      }),
      providesTags: ['DeleteSingleDocuments'],
      transformResponse: (response) => response.results
    })
  })
})

export const {
  useGetDocumentsMutation,
  useGetDocumentsByIdMutation,
  usePostDocumentsMutation,
  useUpdateDocumentsMutation,
  useDeleteDocumentsMutation
} = seekerDocumentApi
